.item {
    height: 20%;
    background-color: transparent;
}
.item-col {
    background-color: transparent;
    border-bottom: 1px solid rgba(56, 56, 56, 0.807);
}
.item-col:hover {
    filter: brightness(95%);
}
.ml-2 {
    margin-left: 0.2rem; /* Puedes ajustar este valor según tus necesidades */
}
.small-font {
    font-size: 8px; /* Ajusta el tamaño de la fuente según tus preferencias */
}