.img-bg {
    background-image:linear-gradient(to right, rgb(0 0 0 / .3), rgb(0 0 0 / .5)), url('./imagenfondo.jpg'); /* Ruta relativa desde la raíz del proyecto */
}
.login-form {
    background-color: rgba(219, 219, 219, 0.866);
}
.login-logo {
    height: 200px; /* Puedes ajustar el valor según tus necesidades */
    width: auto; /* La propiedad 'auto' mantiene la proporción original */
}
.login-card {
    background-color: hsla(0, 0%, 100%, 0.3);
}
.login-bg {
    margin-bottom: 30vh;
    border-radius: 15px;
}
.card-body {
    width: auto;
}

