.login-logo {
    display: flex;
    align-items: center;
    height: 50px;
    width: 50px;
}
.logout {
    height: 30px;
    width: 30px;   
}
.logout-container {
    display: flex;
    align-items: center;
    height: 60%;
    width: 90%;
    background: linear-gradient(#d11212, #d14949, #9c3535, #d11212);
    border-radius: 20px;
    color: white;
    font-family: 'Montserrat Black', sans-serif;
    transition: background-color 0.3s ease; 
}
.logout-container:hover {
    background-color: #F5F5F5; 
  }
.text-sm {
    font-family: 'Montserrat Black', sans-serif;
}