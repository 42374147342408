.footer {
    height: 10%;
}

.contacto ,
.enlaces ,
.redes ,
.footer-col
    {
    background-color: rgba(255, 0, 0, 0.071);
    color: white;
}


.redes {
    font-family: 'Gotham Black', sans-serif;
}
.contacto {
    font-family: 'Gotham Black', sans-serif;
}
.enlaces {
    font-family: 'Gotham Black', sans-serif;
}